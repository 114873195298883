<!--
* @description:
* @fileName shopEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    :before-close="handleClose"
    direction="rtl"
    custom-class="demo-drawer"
  >
    <div class="demo-drawer__content">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="优惠券名称" prop="couponName" :label-width="110">
          <el-input
            v-model="form.couponName"
            autocomplete="off"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponType" :label-width="110">
          <el-select v-model="form.couponType" placeholder="请选择优惠券类型">
            <template v-for="(item, index) in couponTypeOption" :key="index">
              <el-option :label="item.label" :value="item.value"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item
          label="消费门槛"
          prop="consumeThreshold"
          :label-width="90"
        >
          <el-input-number v-model="form.consumeThreshold" :min='0'/>
        </el-form-item>

        <el-form-item label="折扣" prop="couponDiscount" :label-width="90">
          <el-input-number v-model="form.couponDiscount" :min='0'/>
        </el-form-item>

        <el-form-item label="领取限制" prop="couponLimitNum" :label-width="90">
          <el-input-number v-model="form.couponLimitNum" :min='0'/>
        </el-form-item>

        <el-form-item label="发行数量" prop="publishNum" :label-width="90">
          <el-input-number v-model="form.publishNum" :min='0'/>
        </el-form-item>

        <el-form-item label="时间" prop="time" :label-width="90">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            range-separator="至"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="活动描述" prop="description" :label-width="90">
          <el-input
            v-model="form.description"
            maxlength="15"
            placeholder="请输入活动描述"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submitInfo()">提交</el-button>
    </div>
  </el-drawer>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { upLoadImg } from '@/api/active'

  import { addCoupon, editCoupon } from '@/api/ticket'
  import { parseTime } from '@/utils/index'
  export default defineComponent({
    name: 'shopEdit',
    emits: ['fetch-data'],
    props: {
      shopId: {
        type: Number,
        default: null,
      },
    },
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()
      const state = reactive({
        formRef: null,
        mapRef: null,
        checkMenu: [],
        tagOption: [],
        typeOption: [],
        size: '50%',
        form: {
          consumeThreshold: 0,
          couponDiscount: 0,
          couponLimitNum: 0,
          publishNum: 0,
        },
        nowImgList: [],
        rules: {
          couponName: [
            { required: true, trigger: 'blur', message: '请输入优惠券名称' },
          ],
          consumeThreshold: [
            { required: true, trigger: 'blur', message: '请输入消费门槛' },
          ],
          couponDiscount: [
            { required: true, trigger: 'blur', message: '请输入折扣' },
          ],
          couponLimitNum: [
            { required: true, trigger: 'blur', message: '请输入领取限制' },
          ],
          publishNum: [
            { required: true, trigger: 'blur', message: '请输入发行数量' },
          ],
          time: [{ required: true, trigger: 'change', message: '请选择时间' }],
          couponType: [
            { required: true, trigger: 'change', message: '请选择类型' },
          ],
          description: [
            { required: true, trigger: 'blur', message: '请输入活动描述' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        pickerStartDate: null,
        couponTypeOption: [
          {
            label: '折扣券',
            value: 'DISCOUNT',
          },
          {
            label: '满减券',
            value: 'FULL',
          },
        ],
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加优惠券'
        } else {
          state.title = '编辑优惠券'
          state.form = Object.assign({}, row)
          state.form.time = [row.startTime, row.endTime]
        }
        state.dialogFormVisible = true
      }
      const handleClose = () => {
        state['formRef'].resetFields()
        state.form = {
          consumeThreshold: 0,
          couponDiscount: 0,
          couponLimitNum: 0,
          publishNum: 0,
        }
        state.dialogFormVisible = false
      }

      const submitInfo = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let msg = ''
            state.form.startTime = state.form.time[0]
            state.form.endTime = state.form.time[1]
            state.form.shopId = props.shopId
            console.log(state.form)
            if (state.title == '编辑优惠券') {
              let data = await editCoupon(state.form)
              msg = data.msg
            } else {
              let data = await addCoupon(state.form)
              msg = data.msg
            }
            proxy.$baseMessage(`${msg}`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            handleClose()
          }
        })
      }

      // 地图选点

      return {
        ...toRefs(state),
        showEdit,
        handleClose,
        submitInfo,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
